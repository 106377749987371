import React, {Fragment, useEffect, useRef, useState} from 'react';
import Board, {addColumn} from '@asseinfo/react-kanban'
import '@asseinfo/react-kanban/dist/styles.css'
import {Card, CardBody, CardHeader, GridContainer, GridItem} from "../../components";
import TaskLabelStatus from "./components/TaskLabelStatus";
import ViewUserActions from "./components/ViewUserActions";
import {Avatar, Box, List, ListItemText, makeStyles, Tab, Tabs} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {ListItemButton} from "@mui/material";
import {Add, ListAlt, Person, PersonAdd} from "@material-ui/icons";
import {MdOutlineDashboard, MdOutlineModeEdit} from "react-icons/md";
import {BsKanban} from "react-icons/bs";
import {CiBoxList} from "react-icons/ci";
import {FaChartGantt} from "react-icons/fa6";
import api from "../../components/api";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from '@mui/material/Menu';
import {IoMdArrowDropdown, IoMdArrowDropright} from "react-icons/io";
import {
  hexToRgb,
  infoColor,
  primaryColor,
  successColor,
  warningColor
} from "../../assets/jss/material-dashboard-pro-react";
import {FaPlus} from "react-icons/fa";
import Button from "../../components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import TaskManager from "./TaskManager";
import {Gantt, Task, EventOption, StylingOption, ViewMode, DisplayOption} from 'gantt-task-react';
import "gantt-task-react/dist/index.css";
import ModalCalledHistory from "../Modal/CalledHistory";
import TaskViewer from "../Modal/Task/TaskViewer";
import {Doughnut} from "react-chartjs-2";
import {TaskStatusLabel} from "./taskHelper";
import AddToHomeScreen from "@material-ui/icons/AddToHomeScreen";
import SendIcon from "@material-ui/icons/Send";
import {pontuacaoNumero} from "../../components/Funcoes";
import CardIcon from "../../components/Card/CardIcon";
import CardFooter from "../../components/Card/CardFooter";
import {checkBeta} from "../BetaControll";
import dashboardStyle from "../../assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import Swal from "sweetalert2";
const useStyles = makeStyles(dashboardStyle);

const Pipeline = () => {
  const classes = useStyles();

  const [board, setBoard] = useState({columns: []});
  const [pipelines, setPipelines] = useState([]);
  const [currentPipeline, setCurrentPipeline] = useState({id: null, name: null, stage: []});
  const [openListPipelines, setOpenListPipelines] = useState(false);
  const [modalManagerPipeline, setModalManagerPipeline] = useState({open: false, id: null, name: '', picture: ''});

  const [openTaskID, setOpenTaskID] = useState(null);
  const [openChatId, setOpenChatId] = useState(null);

  const [tab, setTab] = useState(0);

  const refMenuPipelines = useRef(null);

  const getPipelines = async (current = null) => {
    const {data} = await api.get('/task/pipeline');
    setPipelines(data);
    const [{id, name}] = data;
    setCurrentPipeline({id, name, stage: []});
    // console.log({id});
    await getPipeline(current || id);
  };

  const createPipeline = async () => {
    const {data} = await api.post(`/task/pipeline`, {name: modalManagerPipeline.name});
    setModalManagerPipeline({open: false, id: null, name: '', picture: ''});
    await Swal.fire({icon: "success", title: 'Pipeline criada com sucesso!'});
    getPipelines(data.id);
  };

  const getPipeline = async (id = currentPipeline.id) => {
    const {data} = await api.get(`/task/pipeline/${id}`);
    setCurrentPipeline(data);
  };

  const changeTask = async (id, params) => {
    await api.put(`/task/${id}`, params);
    setCurrentPipeline(pipeline => {
      pipeline.stage.forEach((stage, k1) => {
        stage.tasks.forEach((task, k2) => {
            if (task.id === id) pipeline.stage[k1].tasks[k2] = {...task, ...params};
          });
      });
      return {...pipeline};
    });

    Swal.mixin({toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, timerProgressBar: true})
      .fire({icon: 'success', title: 'Tarefa alterada com sucesso!'});
  };

  const orderTasks = (tasks, reverse = false) => {
    return tasks.sort((a, b) => {
      if (new Date(a.dateStart) > new Date(b.dateStart)) return reverse ? -1 : 1;
      if (new Date(a.dateStart) < new Date(b.dateStart)) return reverse ? 1 : -1;
      return 0;
    });
  };

  const reducePipelineTasks = pipeline => {
    const tasks = [];
    pipeline.stage.forEach(stage => {
      stage.tasks.forEach(task => tasks.push(task));
    });
    return tasks;
  };

  const reduceSubtasks = (tasks, reduce = true) => {
    const allTasks = [];
    tasks.forEach(task => {
      allTasks.push(task);
      if (reduce && task.subTasks) task.subTasks.forEach(subTask => allTasks.push({...subTask, taskMain: task.id}));
    });
    return allTasks;
  };

  const createTask = async () => {
    const {value} = await Swal.fire({icon: "question", title: 'Qual o nome da tarefa?', input: 'textarea', showCancelButton: true, cancelButtonText: 'Cancelar'});
    if (!value) return;

    await api.post('/task', {title: value, pipeline: {id: currentPipeline.id}});
    await Swal.fire({icon: "success", title: 'Tarefa criada com sucesso!'});
    await getPipeline();
  };

  useEffect(() => {
    getPipelines();
  }, []);

  return (
    <Fragment>
      <Dialog
        open={modalManagerPipeline.open}
        onClose={() => setModalManagerPipeline({open: false, id: null, name: '', picture: ''})}
        fullWidth
      >
        <DialogTitle>
          {modalManagerPipeline.id ? 'Editar' : 'Cadastrar'} Pipeline
        </DialogTitle>
        <DialogContent style={{display: 'flex', alignItems: 'center'}}>
          <Avatar
            alt="Remy Sharp"
            // src="/static/images/avatar/1.jpg"
            style={{width: 80, height: 80}}
          />
          &nbsp;&nbsp;&nbsp;&nbsp;
          <TextField
            autoFocus
            required
            margin="dense"
            label="Nome"
            type="email"
            fullWidth
            variant="standard"
            onChange={({target: {value}}) => setModalManagerPipeline({...modalManagerPipeline, name: value})}
            value={modalManagerPipeline.name}
          />
        </DialogContent>
        <DialogActions>
          <Button color={'hust'} onClick={createPipeline}>
            {modalManagerPipeline.id ? 'Editar' : 'Cadastrar'}
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={refMenuPipelines.current}
        open={openListPipelines}
        onClose={() => setOpenListPipelines(false)}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{horizontal: 'left', vertical: 'top'}}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
      >
        {pipelines.map(prop => (
          <MenuItem
            selected={prop.id === currentPipeline.id}
            key={prop.id}
            onClick={() => {
              getPipeline(prop.id);
              setOpenListPipelines(false);
            }}
          >
            <div style={{width: '100%', display: 'flex', alignItems: 'center', marginRight: 3}}>
              <Avatar/> {prop.name}
            </div>
            <Button
              justIcon
              simple
              color={'primary'}
              round
              style={{width: 30, height: 30, minWidth: 30, padding: 0}}
              onClick={() => {
                setModalManagerPipeline({open: true, id: currentPipeline.id, name: currentPipeline.name, picture: ''});
              }}
            >
              <MdOutlineModeEdit/>
            </Button>
          </MenuItem>
        ))}
        <MenuItem onClick={() => {
          setModalManagerPipeline({open: true, id: null, name: '', picture: ''});
          setOpenListPipelines(false);
        }}>
          <Button
            color={'primary'}
            justIcon
            simple
            round
            style={{width: 30, height: 30, minWidth: 30, padding: 0, marginLeft: -3}}
          >
            <FaPlus/>
          </Button>
          Criar nova pipeline
        </MenuItem>
      </Menu>
      <ModalCalledHistory open={!!openChatId} id={openChatId} onClose={() => setOpenChatId(null)}/>
      <Box sx={{borderBottom: 1, borderColor: 'divider'}} style={{display: 'flex'}}>
        {/*++++++++++++++++++++++++++++++++++++++++++++*/}
        <List ref={refMenuPipelines} component="nav" sx={{bgcolor: 'background.paper'}} style={{width: 250}}>
          <ListItemButton
            aria-expanded={openListPipelines ? 'true' : undefined}
            onClick={() => setOpenListPipelines(!openListPipelines)}
          >
            <ListItemText primary={'Acessando'} secondary={currentPipeline.name}/>
            {!openListPipelines ? <IoMdArrowDropright/> : <IoMdArrowDropdown/>}
          </ListItemButton>
        </List>
        {/*++++++++++++++++++++++++++++++++++++++++++++*/}
        <div style={{alignContent: 'center'}}>
          <Tabs value={tab}>
            {[
              {slug: 'resumo', label: 'Resumo', icon: MdOutlineDashboard},
              {slug: 'quadro', label: 'Quadro', icon: BsKanban, disabled: true},
              {slug: 'lista', label: 'Lista', icon: CiBoxList},
              {slug: 'cronograma', label: 'Cronograma', icon: FaChartGantt, disabled: true},
            ].map((prop, key) => (
              <Tab
                key={prop.slug}
                disabled={prop.disabled}
                label={
                  <div style={{display: 'flex', alignItems: 'center'}} onClick={() => setTab(key)}>
                    <prop.icon/>
                    &nbsp;{prop.label}
                  </div>
                }
              />))}
          </Tabs>
        </div>
      </Box>
      {[1, 2, 3].includes(tab) && (
        <GridItem lg={12} md={12} sm={12} xs={12}>
          {/*{props?.usuario?.id_usuario || 'NADA'}*/}
          <TaskViewer
            open={!!openTaskID}
            id={openTaskID}
            onClose={() => {
              setOpenTaskID(null);
              getPipeline();
            }}
          />
          <Button color={'hust'} onClick={createTask} style={{width: 100, height: 30}}>
            Criar Tarefa
          </Button>
        </GridItem>
      )}
      {tab === 0 && (
        <GridContainer justify={'center'}>
          <GridItem lg={8} md={10} sm={12} xs={12}>
            <GridContainer>
              {[
                {
                  title: 'Total de conexões',
                  icon: AddToHomeScreen,
                  value: 0,
                  button: {name: 'Gerenciar Conexões', location: '/conexoes'}
                },
                {
                  title: 'Total de listas',
                  icon: ListAlt,
                  value: 1,
                  button: {name: 'Gerenciar Listas', location: '/bulkSend/sendingList'}
                },
                {
                  title: 'Total de campanhas',
                  icon: ListAlt,
                  value: 2,
                  button: {name: 'Gerenciar Campanhas', location: '/bulkSend/campaign'}
                },
                {title: 'Total de disparos', icon: SendIcon, value: 3},
              ].map((prop, key) => (
                <GridItem lg={3} md={3} sm={6} xs={12} key={key}>
                  <Card>
                    <CardHeader color="primary" icon>
                      <p className={classes.cardCategory} style={{fontSize: '18px'}}>
                        {prop.title}
                      </p>
                      <br></br>
                      <div stats style={{display: 'flex', justifyContent: 'space-between'}}>
                        <center>
                          <h3 className={classes.cardTitle} style={{marginBottom: '-10px', color: primaryColor[0]}}>
                            <b>{pontuacaoNumero(prop.value)}</b> <small></small>
                          </h3><br/>
                          <div className={classes.stats}>
                            Atualizado agora
                          </div>
                        </center>

                        <CardIcon color="primary" style={{width: '50px', height: '50px', float: 'right'}}>
                          <prop.icon style={{margin: '0 auto'}}/>
                        </CardIcon>
                      </div>
                    </CardHeader>
                    <CardFooter>
                      {prop.button && (
                        <Button fullWidth color={'hust'} round simple onClick={() => {
                          //* * *
                        }}>
                          <prop.icon/>
                          {prop.button.name}
                        </Button>
                      )}
                    </CardFooter>
                  </Card>
                </GridItem>
              ))}
              {/*+++++++++++++++++++++++++++*/}
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <Card>
                  <CardHeader>
                    Visão geral do status
                  </CardHeader>
                  <CardBody>
                    <Doughnut
                      data={{
                        labels: ['Pendente', 'Andamento', 'Aguardando', 'Finalizada'],
                        datasets: [
                          {
                            label: '',
                            data: [
                              reducePipelineTasks(currentPipeline).filter(el => el.status === 'pending').length,
                              reducePipelineTasks(currentPipeline).filter(el => el.status === 'progress').length,
                              reducePipelineTasks(currentPipeline).filter(el => el.status === 'blocked').length,
                              reducePipelineTasks(currentPipeline).filter(el => el.status === 'finished').length
                            ],
                            backgroundColor: [
                              `rgba(${hexToRgb(TaskStatusLabel.pending.colorHex)}, 0.2)`,
                              `rgba(${hexToRgb(TaskStatusLabel.progress.colorHex)}, 0.2)`,
                              `rgba(${hexToRgb(TaskStatusLabel.blocked.colorHex)}, 0.2)`,
                              `rgba(${hexToRgb(TaskStatusLabel.finished.colorHex)}, 0.2)`,
                            ],
                            borderColor: [
                              `rgba(${hexToRgb(TaskStatusLabel.pending.colorHex)}, 1)`,
                              `rgba(${hexToRgb(TaskStatusLabel.progress.colorHex)}, 1)`,
                              `rgba(${hexToRgb(TaskStatusLabel.blocked.colorHex)}, 1)`,
                              `rgba(${hexToRgb(TaskStatusLabel.finished.colorHex)}, 1)`,
                            ],
                            borderWidth: 1,
                          },
                        ]
                      }}
                    />
                  </CardBody>
                </Card>
              </GridItem>
              <GridItem lg={6} md={6} sm={6} xs={12}>
                <Card>
                  <CardBody>
                    Imagine aqui, a lista de atividades
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      )}
      {tab === 1 && (
        <Board
          allowAddCard
          allowAddColumn
          // initialBoard={{columns: []}}
          // boards={board}
          renderColumnHeader={({id, title, color, cards}) => {
            return (
              <div style={{fontSize: 11}}>
              <span
                style={{
                  padding: '0 5px',
                  background: `rgba(${hexToRgb(color)}, 0.2)`,
                  color: color,
                  borderRadius: 5,
                  fontWeight: 600
                }}
              >
                {title}
              </span>
                <span
                  style={{
                    marginLeft: 10,
                    color: '#777777',
                    fontWeight: 700
                  }}
                >
                {cards.length}
              </span>
              </div>
            );
          }}
          renderCard={({id, title, priority, user}, {dragging}) => (
            <div
              style={{
                margin: '5px 0',
                width: 'calc(270px - 30px)',
                background: '#FFFFFF',
                padding: 5,
                fontFamily: "'Roboto'",
                fontWeight: 500,
                borderRadius: 5,
                position: 'relative'
              }}
            >
              <span style={{
                width: 'calc(100% - 40px)',
                // display: 'block',
                lineHeight: 1,

                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2, // Número de linhas desejadas
                WebkitBoxOrient: 'vertical',
                whiteSpace: 'normal',
                fontWeight: 100
              }}>
                {id} - {title}
              </span>
              <div>
                {user ? (
                  <ViewUserActions
                    style={{marginLeft: 0, position: 'absolute', top: 10, right: 5}}
                    name={user.name}
                    profilePicture={user.profilePicture}
                    showRemove
                    onDelete={async () => {
                      // * * *
                    }}
                  />
                ) : ''}
                <TaskLabelStatus simple onChange={() => ''} priority={priority}/>
              </div>
            </div>
          )}
        >
          {{
            columns: currentPipeline.stage.map(prop => ({
              id: prop.id,
              title: prop.name,
              color: prop.color,
              cards: prop.tasks
            }))
          }}
          {/*{board}*/}
        </Board>
      )}
      {tab === 2 && (
        <TaskManager
          tasks={reducePipelineTasks(currentPipeline)}
          getTasks={() => getPipeline()}
          openTask={setOpenTaskID}
          changeTask={changeTask}
        />
      )}
      {tab === 3 && (
        <Fragment>
          <Gantt
            rowHeight={30}
            viewDate={new Date()}
            /*TaskListHeader={({headerHeight}) => {
              return (
                  <div
                    style={{
                      display: 'table-row',
                      listStyle: 'none',
                      height: headerHeight
                    }}
                  >
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>Tarefa</div>
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>Data</div>
                </div>
              );
            }}
            TaskListTable={({rowHeight, tasks, selectedTaskId}) => {
              const {start, name} = tasks.find(el => el.id.toString() === selectedTaskId.toString()) || {};
              return (
                <div
                  style={{
                    display: 'table-row',
                    listStyle: 'none',
                    height: rowHeight
                  }}
                >
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>{name}</div>
                  <div style={{alignContent: "center", display: 'table-cell', minWidth: 155}}>Data</div>
                </div>
              );
            }}*/
            locale={'pt-br'}
            tasks={reduceSubtasks(orderTasks([
              ...reducePipelineTasks(currentPipeline),
              {id: 1000, title: '', dateStart: new Date(), dateDue: new Date()}
            ], true), false).map(prop => {
              if (prop.subTasks) console.log(prop);
              return {
                start: new Date(prop.dateStart),
                end: prop.dateDue ? new Date(prop.dateDue) : new Date(prop.dateStart),
                name: prop.title,
                id: prop.id,
                type: 'task',
                dependencies: [prop.taskMain].filter(el => el),
                progress: prop.status === 'finished' ? 100 : (prop.subTasks ? ((prop.subTasks.filter(el => el.status === 'finished').length / prop.subTasks.length) * 100) : 0),
                isDisabled: true,
                styles: {progressColor: (prop.status === 'finished' ? successColor : infoColor)[1], progressSelectedColor: (prop.status === 'finished' ? successColor : infoColor)[0]},
              };
            })}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default Pipeline;